import React from "react"
import {
  DiscussBox,
  DefinitionBox,
  QuoteBox,
  ToSeeBox,
  ActivityBox,
  CopyrightBox,
  WhoWasBox
} from "../../../components/boxes"
import alphege from "./alphege.jpg"
import alphege2 from "./alphege-2x.jpg"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const Vikings = () => (
  <Layout title="The Vikings">
    <SmallSectionHeader
      color={theme.colors.purple.dark}
      image={headerImage}
      to="/a-walk-through-time"
    >
      A walk through time
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/a-walk-through-time', name: 'A walk through time' },
      { name: 'The Vikings' }
    ]} />
    <Page>
      <h1>The Vikings</h1>
      <p className="page-left">
        From around the year 800 Vikings were raiding along the Kent coast. They first attacked Canterbury in 851 when they were spending the winter on the Isle of Thanet. In 1009 the city of Canterbury made a Danegeld payment of £3000 to Thorkell the Tall. In 1011 after a long siege the Vikings captured the whole city and took Alphege, the Archbishop of Canterbury, hostage. Alphege was held prisoner for a year but forbade anyone to pay his ransom.
      </p>
      <DefinitionBox title="Danegeld" className="page-right">
        Danegeld was a tax raised by English kings. It was paid to the Vikings on the promise they would not invade.
      </DefinitionBox>

      <p className="page-left">
        The Vikings raided places where they believed there were riches to steal. Monasteries were therefore often targeted. We can see evidence of just how wealthy and skilled some people were in Canterbury, particularly at the monasteries, when we look at items that have been found here that date from this time in history.
      </p>

      <DefinitionBox title="Siege" className="page-right">
        A siege is a military tactic where an enemy surrounds a town or building, cutting off essential supplies, with the aim of making those inside surrender.
      </DefinitionBox>

      <hr />

      <WhoWasBox
        name="Archbishop Alphege"
        dates="b. 953 - d. 1012"
        image={{
          src: alphege,
          srcSet: `${alphege} 369w, ${alphege2} 738w`,
          alt: "Archbishop Alphege",
        }}
      >
        <p>Alphege was the Archbishop of Canterbury from 1006 to 1012, a time at which vikings were raiding the area. Alphege was killed on Easter Day. Some accounts say that the Vikings, who were drunk from a feast, pelted him with animal bones. It is believed that as he was dying from his injuries a soldier killed him with a blow from an axe to his head. Some people have interpreted this as an act of mercy. At first the Vikings refused to release Alphege’s body for burial but then a miracle happened – a dead stick which had been covered in Alphege’s blood began to flower. He was buried in honour at St Paul’s Cathedral in London and many pilgrims went to visit the tomb. In 1023 a Viking called King Canute, who was also King of England, had Alphege’s body moved back to Canterbury as a way of saying sorry for his death. After his death, he was canonised in 1068.</p>
      </WhoWasBox>

      <Figure
        caption="Stained Glass Window showing archbishop Alphege. Not a contemporary image. – Canterbury Cathedral, Nt IX"
      >
        <StaticImage
          src="./alphege-windows.jpg"
          alt="Stained Glass windows"
        />
      </Figure>

      <DefinitionBox title="Canonisation">
        To be made a saint.
      </DefinitionBox>

      <DiscussBox>
        What does the story of Archbishop Alphege tell us about the religious beliefs of the Vikings?
      </DiscussBox>

      <p>
        The term Viking refers to all the pagan peoples of Scandinavia who spoke a Germanic language called Old Norse. They lived in Denmark, Norway and Sweden and were mostly farmers, fisherpeople and hunters. They traded regularly with the rest of mainland Europe. Restricted by the natural resources available in their homelands they came to England in search of better farmland.
      </p>

      <hr />

      <h2>Anglo-Saxon Chronicle</h2>
      <p className="page-left">
        Much of our knowledge of the Viking raids on Canterbury comes from the Anglo-Saxon Chronicle. The Anglo-Saxon Chronicle was a year-by-year account of events in England begun in the eighth century by an English King called Alfred and later continued by others. It was written by monks. There are many copies of the Anglo-Saxon Chronicle and they all have slightly different accounts of what happened.
      </p>
      <Figure
        caption="This image shows a manuscript page from the Anglo Saxon Chronicle"
        className="page-right"
      >
        <StaticImage
          src="./chronicle.jpg"
          alt="Anglo-Saxon Chronicle"
        />
      </Figure>

      <QuoteBox cite="Anglo-Saxon Chronicle">
        When they had explored the borough completely, they went to their ships and took the Archbishop with them
      </QuoteBox>

      <DiscussBox>
        Can you research who wrote the Anglo-Saxon chronicles? Based on this, would you say that the representation of the Viking raids on Canterbury in the Anglo-Saxon chronicle is a fair or accurate account? What do you think the Vikings would have written?
      </DiscussBox>
      <DiscussBox>
        Why might a monk writing an account in Britain, Normandy or Ireland want to show the Vikings in a negative light?
      </DiscussBox>
      <ToSeeBox
        items={[
          {
            location: "North Quire Aisle",
            items: [
              {
                title: "Alphege windows",
                content:
                  "High up above the Bible window are three twelfth century roundels telling the story of the Viking siege of Canterbury and the subsequent taking of Alphege. The top roundel shows the Vikings besieging Canterbury. The bottom right hand roundel shows Alphege trying to stop the Viking attack. The bottom left hand roundel shows Alphege captured being taken away on a Viking ship. There are thought to have originally been three further roundels which completed the story.",
              },
            ],
          },
          {
            location: "Quire",
            items: [
              {
                title: "Location of the tomb of Alphege",
                content:
                  "After his death, the body of Alphege was brought back to Canterbury. His shrine (a place believed to be holy because of its association with a person or event) was next to the high altar. An inscription now marks the place.",
              },
            ],
          },
          {
            location: "Chapter House",
            items: [
              {
                title: "East and West Windows",
                content:
                  "In the East Window (1896) you can see Alphege as Archbishop. In the West Window (1903) there is a depiction of his murder.",
              },
            ],
          },
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="A Walk Through Time activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/AWalkThroughTime.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘A walk through time’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "Archbishop Lanfranc", to: "/a-walk-through-time/lanfranc" },
          { name: "Archbishop Thomas Becket", to: "/a-walk-through-time/becket" },
          { name: "The Reformation in England", to: "/a-walk-through-time/reformation" },
        ]}
      />
      <hr />
      <CopyrightBox />
    </Page>
  </Layout>
)

export default Vikings;